export const CURRENT_EDITION = "2025";
export const ACTIVE_EDITION =
  (localStorage.getItem('currentEdition') === null || localStorage.getItem('currentEdition') === '')
    ? localStorage.getItem("currentEdition")
    : CURRENT_EDITION;

export const USER_NOTES = "films/" + ACTIVE_EDITION + "/NOTES/";
export const SIGNIN_FILMS_CODE = []; // 'FILMAX'
export const SIGNUP_NEW_USERS = true; // REGISTRE D'USUARIS OBERT
// export const SIGNIN_YOUNG = false; // INSCRIPCIONS OBERTES CONCURS JOVE
export const SIGNIN_FILMS_START = new Date("2024-10-01"); // YYYY-MM-DD OPERTURA INSCRIPCIONS CONCURS JOVE
export const SIGNIN_FILMS_END = new Date("2025-03-21"); // YYYY-MM-DD TANCAMENT INSCRIPCIONS CONCURS JOVE
export const SIGNIN_FILMS = (Date.now() > SIGNIN_FILMS_START && Date.now() < SIGNIN_FILMS_END ) ? true : false; // INSCRIPCIONS OBERTES SELECCIÓ OFICIAL

export const SIGNIN_YOUNG_START = new Date("2025-02-03"); // YYYY-MM-DD OPERTURA INSCRIPCIONS CONCURS JOVE
export const SIGNIN_YOUNG_END = new Date("2025-03-19"); // YYYY-MM-DD TANCAMENT INSCRIPCIONS CONCURS JOVE
export const SIGNIN_YOUNG = (Date.now() > SIGNIN_YOUNG_START && Date.now() < SIGNIN_YOUNG_END ) ? true : false; // INSCRIPCIONS OBERTES CONCURS JOVE


export const SIGNIN_PITCHS_START = new Date("2025-03-01"); // YYYY-MM-DD OPERTURA INSCRIPCIONS CONCURS JOVE
export const SIGNIN_PITCHS_END = new Date("2025-05-08"); // YYYY-MM-DD TANCAMENT INSCRIPCIONS CONCURS JOVE
export const SIGNIN_PITCHS = (Date.now() > SIGNIN_PITCHS_START && Date.now() < SIGNIN_PITCHS_END ) ? true : false; // INSCRIPCIONS OBERTES CONCURS JOVE

export const MATERIAL_YOUNG_START = new Date("2024-03-20 15:00:00 GMT+0100"); // "2025-03-20 15:00:00 GMT+0100";
export const MATERIAL_YOUNG_END = new Date("2025-03-23 15:00:00 GMT+0100"); // YYYY-MM-DD;


export const RATE_FILMS = true; // VALORACIONS OBERTES
export const TOP_FILMS = false; // false, true, 'admin' or 'dev'
export const MAX_CURTS = 0; // MINUTES
export const MAX_CURTS_NUM = 12; // UNITS
export const MAX_CURTS_FICCIO = 180; // MINUTES
export const MAX_CURTS_DOCS = 60; // MINUTES
export const MAX_CURTS_VIDEOCLIPS = 60; // MINUTES
export const MAX_CENTRES = 120; // MINUTES
export const MAX_CENTRES_PRIMARIA = 60; // MINUTES
export const MAX_CENTRES_SECUNDARIA = 60; // MINUTES
export const MAX_DOCS_NUM = 6; // UNITS
export const MAX_DOCS = 0; // MINUTES
export const MAX_LLARGS_NUM = 6; // UNITS
export const MAX_LLARGS = 0; // MINUTES

export const MATERIAL_ONLINE_DATE = new Date("2025-06-25"); // YYYY-MM-DD;
export const MATERIAL_MOVIE_DATE = new Date("2025-06-25"); // YYYY-MM-DD;


export const JURY_OFICIAL = [
    "tpinel+oficial@fic-cat.cat",
];

export const JURY_YOUNG = [
    "tpinel+jove@fic-cat.cat",
];

export const JURY_SCHOOL = [
    "tpinel+centres@fic-cat.cat",
];

export const PROVIDER = [
    "tpinel+3cat@fic-cat.cat",
];


export const EDITOR = [
    "tpinel+editor@fic-cat.cat",
];
export const COMITE_PITCHS = [
    "cgambin@fic-cat.cat",
    "tpinel@fic-cat.cat",
  ];
export const COMITE_LLARGS = [
  "cgambin@fic-cat.cat",
  "tpinel@fic-cat.cat",
  "pfranquet@fic-cat.cat",
  "pespelt@fic-cat.cat",
];
export const COMITE_DOCS = [
  "cgambin@fic-cat.cat",
  "tpinel@fic-cat.cat",
  "slopez@fic-cat.cat",
  "rriera@fic-cat.cat",
  "mmartinez@fic-cat.cat",
];
export const COMITE_CURTS = [
  "cgambin@fic-cat.cat",
  "tpinel@fic-cat.cat",
  "acortadella@fic-cat.cat",
  "scami@fic-cat.cat",
  "crosa@fic-cat.cat",
  "pespelt@fic-cat.cat",
];

export const COMITE_CENTRES = [
    "cgambin@fic-cat.cat",
    "tpinel@fic-cat.cat",
    "pfranquet@fic-cat.cat",
    "mmartinez@fic-cat.cat",
    "crosa@fic-cat.cat",
    "pespelt@fic-cat.cat",
];

export const COMITE_VIDEOCLIPS = [
    "cgambin@fic-cat.cat",
    "tpinel@fic-cat.cat",
    "slopez@fic-cat.cat",
    "scami@fic-cat.cat",
    "crosa@fic-cat.cat",
];

export const COMITE_JOVES = [];

export const LOCATION = [
  {
    name: "Sala Casino",
    address: "Plaça dels Pins, 6, 43883 Roda de Berà, Tarragona",
    url: "https://maps.app.goo.gl/f8bwSsQRnNDdEvbV8",
    enabled: true,
  },
  {
    name: "Casal Municipal Les Monges",
    address: "Carrer Major, 2, 43883 Roda de Berà, Tarragona",
    url: "https://maps.app.goo.gl/uiMGbzYv5fzQHiap8",
    enabled: true,
  },
  {
    name: "Zona Foodtrucks",
    address: "Plaça dels Pins, 43883 Roda de Berà, Tarragona",
    url: "https://maps.app.goo.gl/EVAh2WsdDbdXTMwf9",
    enabled: true,
  },
  {
    name: "Plaça de la Sardana",
    address: "Plaça de la Sardana, 43883 Roda de Berà, Tarragona",
    url: "https://maps.app.goo.gl/farYHrAXksKJfCwk9",
    enabled: true,
  },
  {
    name: "Centre cívic La Roca Foradada (Museu de la ràdio Luis del Olmo)",
    address: "s/n, 43883 Roda de Berà, Tarragona",
    url: "https://maps.app.goo.gl/u336FjPLvTJjsoVVA",
    enabled: true,
  },
];
export const LOGISTIC = [
  {
    name: "Sònia López",
    phone: "635 862 449",
    mail: "logistica@fic-cat.cat",
    enabled: true,
  },
  {
    name: "Anna Cortadella",
    phone: "635 862 449",
    mail: "logistica@fic-cat.cat",
    enabled: true,
  },
  {
    name: "Claudia Rosa",
    phone: "638 388 234",
    mail: "logistica@fic-cat.cat",
    enabled: true,
  },
  {
    name: "Pilar Franquet",
    phone: "675 178 072",
    mail: "pfranquet@fic-cat.cat",
    enabled: true,
  },
];

export const CATEGORIES = ['Llargmetratge','Curtmetratge']
export const CONTACT_M = ['','-1','-2','-3']
export const SEND_EMAIL_URL = "https://triggermail-e4wjw26kzq-uc.a.run.app";
export const CURTS = [
  "filmFirst",
  "writerName",
  "writerGenre",
  "producerName",
  "producerGenre",
  "filmDirectorGenre",
  "filmTarragona",
  "filmGenre",
  "filmName",
  "filmYear",
  "filmCity",
  "filmState",
  "filmCountry",
  "filmLanguage",
  "filmLangCAT",
  "filmLangES",
  "filmLangEN",
  "filmLangOT",
  "filmLength",
  "filmPremiere",
  "filmFestivals",
  "filmDirector",
  "producerCompany",
  "producerSite",
  "filmActoring",
  "filmSpecialEdition",
  "filmSynopsis",
  "filmTechnicalCast",
  "appTechnicalCast",
  "appShortDescription",
  "isOnline",
  "isOffline",
  "eventDate",
  "eventLinkTv3",
  "eventLinkTv3StartDate",
  "eventLinkTv3EndDate",
  "eventLinkFilmin",
  "contactName",
  "contactRole",
  "contactEmail",
  "contactPhone",
  "imageCover",
  "imageFrame1",
  "imageFrame2",
  "imageFrame3",
  "filmLink",
  "filmPass",
  "acceptOwn",
  "acceptOnline",
];
export const LLARGS = [
  "filmFirst",
  "writerName",
  "writerGenre",
  "producerName",
  "producerGenre",
  "filmDirectorGenre",
  "filmTarragona",
  "filmGenre",
  "filmName",
  "filmYear",
  "filmCity",
  "filmState",
  "filmCountry",
  "filmLanguage",
  "filmLangCAT",
  "filmLangES",
  "filmLangEN",
  "filmLangOT",
  "filmLength",
  "filmPremiere",
  "filmFestivals",
  "filmDirector",
  "producerCompany",
  "producerSite",
  "filmActoring",
  "filmSpecialEdition",
  "filmSynopsis",
  "filmTechnicalCast",
  "appTechnicalCast",
  "appShortDescription",
  "isOnline",
  "isOffline",
  "eventDate",
  "eventLinkTv3",
  "eventLinkTv3StartDate",
  "eventLinkTv3EndDate",
  "eventLinkFilmin",
  "contactName",
  "contactRole",
  "contactEmail",
  "contactPhone",
  "imageCover",
  "imageFrame1",
  "imageFrame2",
  "imageFrame3",
  "filmLink",
  "filmPass",
  "acceptOwn",
  "acceptOnline",
];
export const DOCS = [
  "filmFirst",
  "writerName",
  "writerGenre",
  "producerName",
  "producerGenre",
  "filmDirectorGenre",
  "filmTarragona",
  "filmName",
  "filmYear",
  "filmCity",
  "filmState",
  "filmCountry",
  "filmLanguage",
  "filmLangCAT",
  "filmLangES",
  "filmLangEN",
  "filmLangOT",
  "filmLength",
  "filmPremiere",
  "filmFestivals",
  "filmDirector",
  "producerCompany",
  "producerSite",
  "filmActoring",
  "filmSpecialEdition",
  "filmSynopsis",
  "filmTechnicalCast",
  "appTechnicalCast",
  "appShortDescription",
  "isOnline",
  "isOffline",
  "eventDate",
  "eventLinkTv3",
  "eventLinkTv3StartDate",
  "eventLinkTv3EndDate",
  "eventLinkFilmin",
  "contactName",
  "contactRole",
  "contactEmail",
  "contactPhone",
  "imageCover",
  "imageFrame1",
  "imageFrame2",
  "imageFrame3",
  "filmLink",
  "filmPass",
  "acceptOwn",
  "acceptOnline",
];
export const CENTRES = [
  "filmGenre",
  "filmSchool",
  "filmSchoolLevel",
  "filmSchoolTutor",
  "filmName",
  "filmYear",
  "filmCity",
  "filmState",
  "filmCountry",
  "filmLanguage",
  "filmLangCAT",
  "filmLangES",
  "filmLangEN",
  "filmLangOT",
  "filmLength",
  "filmPremiere",
  "filmFestivals",
  "filmDirector",
  "filmActoring",
  "filmSpecialEdition",
  "filmSynopsis",
  "filmTechnicalCast",
  "appTechnicalCast",
  "appShortDescription",
  "isOnline",
  "isOffline",
  "eventDate",
  "eventLinkTv3",
  "eventLinkTv3StartDate",
  "eventLinkTv3EndDate",
  "eventLinkFilmin",
  "contactName",
  "contactRole",
  "contactEmail",
  "contactPhone",
  "imageCover",
  "imageFrame1",
  "imageFrame2",
  "imageFrame3",
  "filmLink",
  "filmPass",
  "acceptOwn",
  "acceptOnline",
];
export const VIDEOCLIPS = [
  "filmFirst",
  "producerName",
  "producerGenre",
  "filmDirectorGenre",
  "filmTarragona",
  "filmBand",
  "filmName",
  "filmYear",
  "filmCity",
  "filmState",
  "filmCountry",
  "filmLanguage",
  "filmLangCAT",
  "filmLangES",
  "filmLangEN",
  "filmLangOT",
  "filmLength",
  "filmPremiere",
  "filmFestivals",
  "filmDirector",
  "producerCompany",
  "producerSite",
  "filmActoring",
  "filmSpecialEdition",
  "filmSynopsis",
  "filmTechnicalCast",
  "appTechnicalCast",
  "appShortDescription",
  "isOnline",
  "isOffline",
  "eventDate",
  "eventLinkTv3",
  "eventLinkTv3StartDate",
  "eventLinkTv3EndDate",
  "eventLinkFilmin",
  "contactName",
  "contactRole",
  "contactEmail",
  "contactPhone",
  "imageCover",
  "imageFrame1",
  "imageFrame2",
  "imageFrame3",
  "filmLink",
  "filmPass",
  "acceptOwn",
  "acceptOnline",
];
export const PITCHS = [
  "imageFile",
  "pdfFile",
  "filmCity",
  "filmState",
  "filmCountry",
  "filmName",
  "filmCategory",
  "filmGenre",
  "filmLanguage",
  "filmLangCAT",
  "filmLangES",
  "filmLangEN",
  "filmLangOT",
  "filmTechnicalCast",
  "appTechnicalCast",
  "appShortDescription",
  "isOnline",
  "isOffline",
  "eventDate",
  "eventLinkYouTube",
  "contactName",
  "contactEmail",
  "contactPhone",
  "contactNIF",
  "contactRole",
  "contactBirth",
  "contactName-1",
  "contactEmail-1",
  "contactPhone-1",
  "contactNIF-1",
  "contactRole-1",
  "contactBirth-1",
];
export const JOVES = [
    "filmCity",
    "filmState",
    "filmCountry",
    "filmTechnicalCast",
    "appTechnicalCast",
    "appShortDescription",
    "isOnline",
    "isOffline",
    "eventDate",
    "eventLinkYouTube",
    "contactName",
    "contactEmail",
    "contactPhone",
    "contactNIF",
    "contactBirth",
  ];
export const JOVES_FILM = [
   "filmName",
   "filmSynopsis",
   "filmLength",
   "filmYear",
   "filmGenre",
   "filmCountry",
   "filmState",
   "filmCity",
   "filmLanguage",
   "filmLangCAT",
   "filmLangES",
   "filmLangEN",
   "filmLangOT",
   "filmLength",
   "filmDirector",
   "filmDirectorGenre",
   "producerName",
   "producerGenre",
   "writerName",
   "writerGenre",
   "filmActoring"
  ];
export const GENRES = [
  "Acció",
  "Animació",
  "Aventura",
  "Catàstrofe",
  "Ciència Ficció",
  "Comèdia",
  "Drama",
  "Fantasia",
  "Musical",
  "Suspens",
  "Terror",
  "Altres",
];
export const SPAIN_STATES = [
  "Àlaba",
  "Albacete",
  "Alacant",
  "Almeria",
  "Astúries",
  "Avila",
  "Badajoz",
  "Barcelona",
  "Burgos",
  "Càceres",
  "Cadis",
  "Cantàbria",
  "Castelló",
  "Ciudad Real",
  "Còrdova",
  "La Corunya",
  "Conca",
  "Girona",
  "Granada",
  "Guadalajara",
  "Guipúscoa",
  "Huelva",
  "Osca",
  "Illes Balears",
  "Jaén",
  "Lleó",
  "Lleida",
  "Lugo",
  "Madrid",
  "Màlaga",
  "Múrcia",
  "Navarra",
  "Orense",
  "Palència",
  "Las Palmas",
  "Pontevedra",
  "la Rioja",
  "Salamanca",
  "Segòvia",
  "Sevilla",
  "Soria",
  "Tarragona",
  "Santa Cruz de Tenerife",
  "Terol",
  "Toledo",
  "València",
  "Valladolid",
  "Biscaia",
  "Zamora",
  "Saragossa",
];
export const TARRAGONA_CITIES = [
  "Aiguamúrcia",
  "Albinyana",
  "Albiol",
  "Alcanar",
  "Alcover",
  "Aldea",
  "Aldover",
  "Aleixar",
  "Alfara de Carles",
  "Alforja",
  "Alió",
  "Almoster",
  "Altafulla",
  "Ametlla de Mar",
  "Ampolla",
  "Amposta",
  "Arboç",
  "Arbolí",
  "Argentera",
  "Arnes",
  "Ascó",
  "Banyeres del Penedès",
  "Barberà de la Conca",
  "Batea",
  "Bellmunt del Priorat",
  "Bellvei",
  "Benifallet",
  "Benissanet",
  "Bisbal de Falset",
  "Bisbal del Penedès",
  "Blancafort",
  "Bonastre",
  "Borges del Camp",
  "Bot",
  "Botarell",
  "Bràfim",
  "Cabacés",
  "Cabra del Camp",
  "Calafell",
  "Camarles",
  "Cambrils",
  "Canonja",
  "Capafonts",
  "Capçanes",
  "Caseres",
  "Castellvell del Camp",
  "Catllar, el",
  "Colldejou",
  "Conesa",
  "Constantí",
  "Corbera d'Ebre",
  "Cornudella de Montsant",
  "Creixell",
  "Cunit",
  "Deltebre",
  "Duesaigües",
  "Espluga de Francolí",
  "Falset",
  "Fatarella",
  "Febró",
  "Figuera",
  "Figuerola del Camp",
  "Flix",
  "Forès",
  "Freginals",
  "Galera",
  "Gandesa",
  "Garcia",
  "Garidells",
  "Ginestar",
  "Godall",
  "Gratallops",
  "Guiamets",
  "Horta de Sant Joan",
  "Lloar",
  "Llorac",
  "Llorenç del Penedès",
  "Marçà",
  "Margalef",
  "Mas de Barberans",
  "Masdenverge",
  "Masllorenç",
  "Masó",
  "Maspujols",
  "Masroig",
  "Milà",
  "Miravet",
  "Molar",
  "Mont-ral",
  "Mont-roig del Camp",
  "Montblanc",
  "Montbrió del Camp",
  "Montferri",
  "Montmell",
  "Móra d'Ebre",
  "Móra la Nova",
  "Morell",
  "Morera de Montsant",
  "Nou de Gaià",
  "Nulles",
  "Pallaresos",
  "Palma d'Ebre",
  "Passanant i Belltall",
  "Paüls",
  "Perafort",
  "Perelló",
  "Piles, les",
  "Pinell de Brai",
  "Pira",
  "Pla de Santa Maria",
  "Pobla de Mafumet",
  "Pobla de Massaluca",
  "Pobla de Montornès",
  "Poboleda",
  "Pont d'Armentera",
  "Pontils",
  "Porrera",
  "Pradell de la Teixeta",
  "Prades",
  "Prat de Comte",
  "Pratdip",
  "Puigpelat",
  "Querol",
  "Rasquera",
  "Renau",
  "Reus",
  "Riba",
  "Riba-roja d'Ebre",
  "Riera de Gaià",
  "Riudecanyes",
  "Riudecols",
  "Riudoms",
  "Rocafort de Queralt",
  "Roda de Berà",
  "Rodonyà",
  "Roquetes",
  "Rourell",
  "Salomó",
  "Salou",
  "Sant Carles de la Ràpita",
  "Sant Jaume d'Enveja",
  "Sant Jaume dels Domenys",
  "Santa Bàrbara",
  "Santa Coloma de Queralt",
  "Santa Oliva",
  "Sarral",
  "Savallà del Comtat",
  "Secuita, la",
  "Selva del Camp",
  "Senan",
  "Sénia",
  "Solivella",
  "Tarragona",
  "Tivenys",
  "Tivissa",
  "Torre de Fontaubella",
  "Torre de l'Espanyol",
  "Torredembarra",
  "Torroja del Priorat",
  "Tortosa",
  "Ulldecona",
  "Ulldemolins",
  "Vallclara",
  "Vallfogona de Riucorb",
  "Vallmoll",
  "Valls",
  "Vandellòs i l'Hospitalet de l'Infant",
  "Vendrell",
  "Vespella de Gaià",
  "Vila-rodona",
  "Vila-seca",
  "Vilabella",
  "Vilalba dels Arcs",
  "Vilallonga del Camp",
  "Vilanova d'Escornalbou",
  "Vilanova de Prades",
  "Vilaplana",
  "Vilaverd",
  "Vilella Alta",
  "Vilella Baixa",
  "Vimbodí i Poblet",
  "Vinebre",
  "Vinyols i els Arcs",
  "Xerta",
];

export const COUNTRIES = [
  "Espanya",
  "Afganistan",
  "Albània",
  "Algèria",
  "Samoa Americana",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antàrtida",
  "Antigua i Barbuda",
  "Argentina",
  "Armènia",
  "Aruba",
  "Austràlia",
  "Àustria",
  "Azerbaidjan",
  "Bahames (les)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Bielorússia",
  "Bèlgica",
  "Belize",
  "Benín",
  "Bermuda",
  "Bhutan",
  "Bolívia (estat plurinacional de)",
  "Bonaire, Sint Eustatius i Saba",
  "Bòsnia i Hercegovina",
  "Botswana",
  "Illa Bouvet",
  "Brasil",
  "Territori britànic de l'oceà Índic (el)",
  "Brunei Darussalam",
  "Bulgària",
  "Burkina Faso",
  "Burundi",
  "Cap Verd",
  "Cambodja",
  "Camerun",
  "Canadà",
  "Illes Caiman (les)",
  "República Centreafricana (la)",
  "Txad",
  "Xile",
  "Xina",
  "Illa de Nadal",
  "Illes Cocos (Keeling) (les)",
  "Colòmbia",
  "Comores (les)",
  "Congo (República Democràtica del)",
  "Congo (el)",
  "Illes Cook (les)",
  "Costa Rica",
  "Croàcia",
  "Cuba",
  "Curaçao",
  "Xipre",
  "Txèquia",
  "Costa d'Ivori",
  "Dinamarca",
  "Djibouti",
  "Dominica",
  "República Dominicana (la)",
  "Equador",
  "Egipte",
  "El Salvador",
  "Guinea Equatorial",
  "Eritrea",
  "Estònia",
  "Eswatini",
  "Etiòpia",
  "Illes Malvines [Malvinas]",
  "Illes Fèroe (les)",
  "Fiji",
  "Finlàndia",
  "França",
  "Guaiana Francesa",
  "Polinèsia Francesa",
  "Territoris australs francesos (els)",
  "Gabon",
  "Gàmbia (el)",
  "Geòrgia",
  "Alemanya",
  "Ghana",
  "Gibraltar",
  "Grècia",
  "Groenlàndia",
  "Granada",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haití",
  "Heard Island i McDonald Islands",
  "Santa Seu (la)",
  "Hondures",
  "Hong Kong",
  "Hongria",
  "Islàndia",
  "Índia",
  "Indonèsia",
  "Iran (República Islàmica de)",
  "Iraq",
  "Irlanda",
  "Illa de l'home",
  "Israel",
  "Itàlia",
  "Jamaica",
  "Japó",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Corea (República Popular Democràtica de)",
  "Corea (la República de)",
  "Kuwait",
  "Kirguizistan",
  "República Democràtica Popular Lao (la)",
  "Letònia",
  "Líban",
  "Lesotho",
  "Libèria",
  "Líbia",
  "Liechtenstein",
  "Lituània",
  "Luxemburg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malàisia",
  "Maldives",
  "Mali",
  "Malta",
  "Illes Marshall (les)",
  "Martinica",
  "Mauritània",
  "Maurici",
  "Mayotte",
  "Mèxic",
  "Micronèsia (Estats federats de)",
  "Moldàvia (la República de)",
  "Mònaco",
  "Mongòlia",
  "Montenegro",
  "Montserrat",
  "Marroc",
  "Moçambic",
  "Myanmar",
  "Namíbia",
  "Nauru",
  "Nepal",
  "Països Baixos (els)",
  "Nova Caledònia",
  "Nova Zelanda",
  "Nicaragua",
  "Níger (el)",
  "Nigèria",
  "Niue",
  "Illa Norfolk",
  "Illes Mariannes del Nord (les)",
  "Noruega",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestina, Estat de",
  "Panamà",
  "Papua Nova Guinea",
  "Paraguai",
  "Perú",
  "Filipines (les)",
  "Pitcairn",
  "Polònia",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "República de Macedònia del Nord",
  "Romania",
  "Federació Russa (la)",
  "Ruanda",
  "Reunió",
  "Saint Barthélemy",
  "Santa Helena, Ascensió i Tristan da Cunha",
  "Saint Kitts i Nevis",
  "Santa Llúcia",
  "Sant Martí (part francesa)",
  "Saint Pierre i Miquelon",
  "Sant Vicenç i les Granadines",
  "Samoa",
  "San Marino",
  "Sao Tomé i Príncep",
  "Aràbia Saudita",
  "Senegal",
  "Sèrbia",
  "Seychelles",
  "Sierra Leone",
  "Singapur",
  "Sint Maarten (part holandesa)",
  "Eslovàquia",
  "Eslovènia",
  "Illes Salomó",
  "Somàlia",
  "Sud-Àfrica",
  "Geòrgia del Sud i les illes Sandwich del Sud",
  "Sudan del Sud",
  "Sri Lanka",
  "Sudan (el)",
  "Surinam",
  "Svalbard i Jan Mayen",
  "Suècia",
  "Suïssa",
  "República Àrab de Síria",
  "Taiwan",
  "Tadjikistan",
  "Tanzània, República Unida de",
  "Tailàndia",
  "Timor-Leste",
  "Anar",
  "Tokelau",
  "Tonga",
  "Trinitat i Tobago",
  "Tunísia",
  "Turquia",
  "Turkmenistan",
  "Illes Turks i Caicos (les)",
  "Tuvalu",
  "Uganda",
  "Ucraïna",
  "Emirats Àrabs Units (els)",
  "Regne Unit de Gran Bretanya i Irlanda del Nord (el)",
  "Illes perifèriques menors dels Estats Units (les)",
  "Estats Units d'Amèrica (els)",
  "Uruguai",
  "Uzbekistan",
  "Vanuatu",
  "Veneçuela (República Bolivariana de)",
  "Vietnam",
  "Illes Verges (britàniques)",
  "Illes Verges (EUA)",
  "Wallis i Futuna",
  "Sàhara Occidental",
  "Iemen",
  "Zàmbia",
  "Zimbabwe",
  "Illes Åland",
];
